import React from 'react';
import { Link, Navigate} from 'react-router-dom';
import log from '../images/PosiChargeLogo.svg';
import Ampure from '../images/Ampure234x50.jpg'
import constantsUrl from '../settings/constants';
import { Utility } from "../helper/index";
import service from '../service/api/common';
import * as roles from '../settings/roles';

interface MyState {
    subMenu1: boolean, subMenu2: boolean, subMenu3: boolean, subMenu4: boolean, OEMeterLog_R: boolean,
    subMenu5: boolean, subMenu6: boolean, subMenu7: boolean, subMenu8: boolean, subMenu9: boolean, subMenu10: boolean, subMenu11: boolean, subMenu14: boolean,
    userRole: string | null, token: string, isRedirectLogin: boolean, companyDashBoard_W: boolean, siteDashBoard_W: boolean, eq_log_report_W: boolean,
    dealerDashBoard_W: boolean, locationDashBoard_W: boolean, reportController_W: boolean, adminDashBoard_W: boolean, userMaintenanceR_W: boolean,
    organizationMaintenanceR_W: boolean, locationMaintenanceR_W: boolean, divisionMaintenanceR_W: boolean, siteMaintenanceR_W: boolean, BbmidInventory_W: boolean, accountgeneral_W: boolean, charge_Log_Report_W: boolean, Account_Profile_W: boolean, Issues_report1_W: boolean
    battery_history_logs_W: boolean, permissions: string[],redirectPath:string, NOBMID:string, BMID:string
}

export default class PosinetMenu extends React.Component<any, MyState> {
    constructor(props: any) {
        super(props)
        this.state = {
            subMenu1: false,
            subMenu2: false,
            subMenu3: false,
            subMenu4: false,
            subMenu5: false,
            subMenu6: false,
            subMenu7: false,
            subMenu8: false,
            subMenu9: false,
            subMenu10: false,
            subMenu11: false,
            subMenu14: false,
            userRole: "",
            token: "",
            isRedirectLogin: false,
            adminDashBoard_W: false,
            dealerDashBoard_W: false,
            companyDashBoard_W: false,
            siteDashBoard_W: false,
            locationDashBoard_W: false,
            reportController_W: false,
            userMaintenanceR_W: false,
            organizationMaintenanceR_W: false,
            locationMaintenanceR_W: false,
            divisionMaintenanceR_W: false,
            siteMaintenanceR_W: false,
            BbmidInventory_W: false,
            accountgeneral_W: false,
            Account_Profile_W: false,
            Issues_report1_W: false,
            eq_log_report_W: false,
            OEMeterLog_R: false,
            charge_Log_Report_W: false,
            battery_history_logs_W: false,
            permissions: [],
            redirectPath:"",
            NOBMID :'NOBMID',
            BMID : 'BMID'
        }
        this.showSubMenu = this.showSubMenu.bind(this)
        this.clickSubMenu = this.clickSubMenu.bind(this)
        this.redirectToHome = this.redirectToHome.bind(this)
        this.expandDashboard = this.expandDashboard.bind(this)
    }

    componentDidMount(): void {
        if (Utility.hasUserEmpty()) {
            this.setState({ isRedirectLogin: true })
        } else {
            let userInfo: any = Utility.getUserInfo();
            var gettoken = userInfo.authToken;
            this.setState({ token: gettoken })
        }
        (async () => {
            try {
                const response = await service({
                    url: constantsUrl.api.userRole.getRoleAccess,
                    method: "GET",
                    headers: { accept: "*/*", Authorization: "Bearer " + gettoken },
                });
                let permissionData = response.roleAccess;
                this.setState({ permissions: permissionData })
                localStorage.setItem("permissions", response?.roleAccess)
                let dealer_permission = permissionData.includes("dealerDashBoard_W")
                let company_permission = permissionData.includes("companyDashBoard_W")
                let location_permission = permissionData.includes("locationDashBoard_W")
                let site_permission = permissionData.includes("siteDashBoard_W")
                let report_permission = permissionData.includes("report-controller_W")
                let admin_permission = permissionData.includes("adminDashBoard_W")
                let userMaintenanceR_W = permissionData.includes('userMaintenanceR_W')
                let organizationMaintenanceR_W = permissionData.includes('organizationMaintenanceR_W')
                let locationMaintenanceR_W = permissionData.includes('locationMaintenanceR_W')
                let divisionMaintenanceR_W = permissionData.includes('divisionMaintenanceR_W')
                let siteMaintenanceR_W = permissionData.includes('siteMaintenanceR_W')
                let BbmidInventory_W = permissionData.includes('BbmidInventory_W');
                let accountgeneral_W = permissionData.includes('accountgeneral_W');
                let Account_Profile_W = permissionData.includes('Account_Profile_W');
                let Issues_report1_W = permissionData.includes('Issues_report1_W');
                let eq_log_report_W = permissionData.includes('eq_log_report_W');
                let OEMeterLog_R = permissionData.includes('OEMeterLog_R');
                let battery_history_logs_W = permissionData.includes('battery_history_logs_W');
                let charge_Log_Report_W = permissionData.includes('charge_Log_Report_W');
                this.setState({
                    battery_history_logs_W, charge_Log_Report_W, dealerDashBoard_W: dealer_permission, companyDashBoard_W: company_permission, locationDashBoard_W: location_permission, siteDashBoard_W: site_permission,
                    reportController_W: report_permission, adminDashBoard_W: admin_permission, userMaintenanceR_W, eq_log_report_W, OEMeterLog_R,
                    organizationMaintenanceR_W, locationMaintenanceR_W, divisionMaintenanceR_W, siteMaintenanceR_W, BbmidInventory_W: BbmidInventory_W, accountgeneral_W: accountgeneral_W, Account_Profile_W: Account_Profile_W, Issues_report1_W: Issues_report1_W
                })

            } catch (error) {
                console.log("getOrg:", error);
            }
            this.redirectToHome()

        })();
    }

    showSubMenu(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
        let Id = event.currentTarget.id
        if (Id === "menu1") {
            this.setState({ subMenu1: !this.state.subMenu1, subMenu2: false, subMenu3: false, subMenu4: false, subMenu5: false, subMenu6: false, subMenu7: false, subMenu8: false, subMenu9: false, subMenu10: false, subMenu11: false, subMenu14: false })
            return
        } else if (Id === "menu2") {
            this.setState({ subMenu1: false, subMenu2: !this.state.subMenu2, subMenu3: false, subMenu4: false, subMenu5: false, subMenu6: false, subMenu7: false, subMenu8: false, subMenu9: false, subMenu10: false, subMenu11: false, subMenu14: false })
            return
        } else if (Id === "menu3") {
            this.setState({ subMenu1: false, subMenu2: false, subMenu3: !this.state.subMenu3, subMenu4: false, subMenu5: false, subMenu6: false, subMenu7: false, subMenu8: false, subMenu9: false, subMenu10: false, subMenu14: false })
        } else if (Id === "menu4") {
            this.setState({ subMenu1: false, subMenu2: false, subMenu3: false, subMenu4: !this.state.subMenu4, subMenu5: false, subMenu6: false, subMenu7: false, subMenu8: false, subMenu9: false, subMenu10: false, subMenu14: false })
        } else if (Id === "menu5") {
            this.setState({ subMenu1: false, subMenu2: false, subMenu3: false, subMenu4: false, subMenu5: !this.state.subMenu5, subMenu6: false, subMenu7: false, subMenu8: false, subMenu9: false, subMenu10: false, subMenu14: false })
        } else if (Id === "menu6") {
            this.setState({ subMenu1: false, subMenu2: false, subMenu3: false, subMenu4: false, subMenu5: false, subMenu6: !this.state.subMenu6, subMenu7: false, subMenu8: false, subMenu9: false, subMenu10: false, subMenu14: false })
        } else if (Id === "menu7") {
            this.setState({ subMenu1: false, subMenu2: false, subMenu3: false, subMenu4: false, subMenu5: false, subMenu6: false, subMenu7: !this.state.subMenu7, subMenu8: false, subMenu9: false, subMenu10: false, subMenu11: false, subMenu14: false })
        } else if (Id === "menu8") {
            this.setState({ subMenu1: false, subMenu2: false, subMenu3: false, subMenu4: false, subMenu5: false, subMenu6: false, subMenu7: false, subMenu8: !this.state.subMenu8, subMenu9: false, subMenu10: false, subMenu11: false, subMenu14: false })
        } else if (Id === "menu9") {
            this.setState({ subMenu1: false, subMenu2: false, subMenu3: false, subMenu4: false, subMenu5: false, subMenu6: false, subMenu7: false, subMenu8: false, subMenu9: !this.state.subMenu9, subMenu10: false, subMenu11: false, subMenu14: false })
        } else if (Id === "menu10") {
            this.setState({ subMenu1: false, subMenu2: false, subMenu3: false, subMenu4: false, subMenu5: false, subMenu6: false, subMenu7: false, subMenu8: false, subMenu9: false, subMenu10: !this.state.subMenu10, subMenu11: false, subMenu14: false })
        } else if (Id === "menu14") {
            this.setState({ subMenu1: false, subMenu2: false, subMenu3: false, subMenu4: false, subMenu5: false, subMenu6: false, subMenu7: false, subMenu8: false, subMenu9: false, subMenu10: false, subMenu11: false, subMenu14: !this.state.subMenu14 })
        }
    }

    clickSubMenu = (value:string) => {
        localStorage.setItem('menuId',value)
        this.props.getSideMenu(value)
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<MyState>, snapshot?: any): void {
        if (prevState.userRole === "") {
            this.setState({ userRole: localStorage.getItem("UserType") })
        }
    }

    redirectToHome = () => {
        const UserType = localStorage.getItem('UserType')
        switch (UserType) {
            case roles.PLATFORM_ADMIN:
            case roles.PLATFORM_MANAGER:
            case roles.DEALER_ADMIN:
            case roles.DEALER_MANAGER:
                this.setState({ redirectPath: '/admindashboard' })
                break;
            case roles.COMPANY_ADMIN:
            case roles.COMPANY_MANAGER:
                this.setState({ redirectPath: '/companydashboard' })
                break;
            case roles.LOCATION_ADMIN:
            case roles.LOCATION_MANAGER:
                this.setState({ redirectPath: '/locationdashboard' })
                break;
            case roles.SITE_ADMIN:
            case roles.SITE_MANAGER:
                this.setState({ redirectPath: '/sitedashboard' })
                break;
        }
    }

    expandDashboard = () => {
        this.setState({ subMenu1: true, subMenu2: false, subMenu3: false, subMenu4: false, subMenu5: false, subMenu6: false, subMenu7: false, subMenu8: false, subMenu9: false, subMenu10: false, subMenu11: false })
    }



    render() {
        const { subMenu1, subMenu4, subMenu5, subMenu6, subMenu7, subMenu8, subMenu10, dealerDashBoard_W, NOBMID, BMID } = this.state;

        return (
            <nav className="sidebar border-right">
                <div className="logo d-flex justify-content-between">
                    <Link to={this.state.redirectPath} 
                      onClick={()=>{
                        this.expandDashboard();
                        this.clickSubMenu(NOBMID)
                      } 
                      }>
                        <img src={Ampure} alt=""></img>
                    </Link>
                </div>
                <div className="menuItemScroll scrollable-container">
                    <ul id="sidebar_menu">
                        <li>
                            <a href="#" onClick={e => e.preventDefault()} className={subMenu1 ? 'active' : 'unactive'} aria-expanded="true">
                                <span onClick={this.showSubMenu} className='flexJustify' id='menu1' ><span>Dashboard</span> <i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                            </a>
                            <ul className={subMenu1 ? 'sub-menu open' : 'sub-menu'} id="sidebar_menu">
                                {this.state.permissions?.includes("adminDashBoard_W") ? <li><Link onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu1' className="p-0"  to={"/admindashboard"}  >Admin Dashboard</Link> </li> : <></>}
                                {this.state.permissions?.includes("dealerDashBoard_W") ? <li><Link onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu1' state={{ dealerDashBoard_W }} className="p-0" to={"/dealerdashboard"} >Dealer Dashboard</Link> </li> : <></>}
                                {this.state.permissions?.includes("companyDashBoard_W") ? <li><Link onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu1' className="p-0" to="/companydashboard" >Company Dashboard</Link> </li> : <></>}
                                {this.state.permissions?.includes("locationDashBoard_W") ? <li><Link onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu1' className="p-0" to="/locationdashboard" > Location Dashboard</Link></li> : <></>}
                                {this.state.permissions?.includes("siteDashBoard_W") ? <li><Link onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu1' className="p-0" to="/sitedashboard">Site Dashboard</Link></li> : <></>}
                            </ul>
                        </li>
                        <li>
                            {this.state.permissions?.includes("BbmidInventory_W") ?
                                <Link to="/bmidInventory" onClick={(event) => {
                                    this.showSubMenu(event);
                                    this.clickSubMenu(BMID);
                                  }}  className={this.state.subMenu2 ? 'active' : 'unactive'} aria-expanded="false" id='menu2'>
                                    <span  className="flexJustify" ><span>Battery Management</span> </span>
                                </Link> : <></>
                            }
                        </li>
                        <li>
                            <Link to="/faultsandwarnings" onClick={(event) => {
                                this.showSubMenu(event);
                                this.clickSubMenu(NOBMID);
                            }} className={this.state.subMenu14 ? 'active' : 'unactive'} aria-expanded="false" id='menu14'>
                                <span className="flexJustify">Faults & Warnings</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/operationStatusHighTemp" onClick={(event) => {
                                this.showSubMenu(event);
                                this.clickSubMenu(NOBMID);
                            }} className={this.state.subMenu3 ? 'active' : 'unactive'} aria-expanded="false" id='menu3'>
                                <span>Operation Status</span>
                            </Link>
                        </li> */}
                        {/* <li>
                            <a href="#" onClick={e => e.preventDefault()} className={subMenu4 ? 'active' : 'unactive'} aria-expanded="false">
                                <span onClick={this.showSubMenu} className="flexJustify" id='menu4' > <span>Alerts</span> <i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                            </a>
                            <ul className={subMenu4 ? 'sub-menu open' : 'sub-menu'} id="sidebar_menu">
                                {
                                    this.state.permissions?.includes("Issues_report1_W") ?
                                        <li>
                                            <Link to="/issues-report" onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu6' className="p-0" aria-expanded="false">Weekly Issues</Link>
                                        </li>
                                        : <></>
                                }
                            </ul>
                        </li> */}
                        <li>
                            <a href="#" onClick={e => e.preventDefault()} className={subMenu5 ? 'active' : 'unactive'} aria-expanded="false">
                                <span onClick={this.showSubMenu} className="flexJustify" id='menu5' > <span>Location Management</span> <i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                            </a>
                            <ul className={subMenu5 ? 'sub-menu open' : 'sub-menu'} id="sidebar_menu">
                                {
                                    this.state.permissions?.includes("locationMaintenanceR_W") ?
                                        <li><Link onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu7' className="p-0" to={"/locationmaintenance"} >Locations List</Link> </li>
                                        : <></>
                                }
                                {/* <li>
                                    <Link to="/billing-summary-report" className="p-0" aria-expanded="false">Billabale Report</Link>
                                </li> */}
                                {
                                    this.state.permissions?.includes("siteMaintenanceR_W") ?
                                        <li><Link to={"/sitemaintenance"} onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu8' className="p-0" >Sites List</Link> </li> : <></>
                                }
                            </ul>
                        </li>
                        {
                            this.state.permissions?.includes("organizationMaintenanceR_W") ?
                                <li>
                                    <a href="#" onClick={e => e.preventDefault()} className={subMenu8 ? 'active' : 'unactive'} aria-expanded="false">
                                        <span onClick={this.showSubMenu} className="flexJustify" id='menu8' > <span>Organization Management</span> <i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                                    </a>
                                    <ul className={subMenu8 ? 'sub-menu open' : 'sub-menu'} id="sidebar_menu">
                                        <li onClick={e => e.preventDefault()}>
                                            <Link  to="/organizationmaintenance" onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu9' className="p-0" aria-expanded="false">Organizations List</Link>
                                        </li>
                                    </ul>
                                </li> : <></>
                        }
                        {
                            this.state.permissions?.includes("divisionMaintenanceR_W") ?
                                <li>
                                    <a href="#" onClick={e => e.preventDefault()} className={subMenu10 ? 'active' : 'unactive'} aria-expanded="false">
                                        <span onClick={this.showSubMenu} className="flexJustify" id='menu10' > <span>Division Management</span> <i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                                    </a>
                                    <ul className={subMenu10 ? 'sub-menu open' : 'sub-menu'} id="sidebar_menu">
                                        <li onClick={e => e.preventDefault()}>
                                            <Link to="/divisionmaintenance" onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu10' className="p-0" aria-expanded="false">Division List</Link>
                                        </li>
                                    </ul>
                                </li> : <></>
                        }
                        <li>
                            <a href="#" onClick={e => e.preventDefault()} className={subMenu6 ? 'active' : 'unactive'} aria-expanded="false">
                                <span onClick={this.showSubMenu} className="flexJustify" id='menu6' > <span>User Management</span> <i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                            </a>
                            <ul className={subMenu6 ? 'sub-menu open' : 'sub-menu'} id="sidebar_menu">
                                {
                                    this.state.permissions?.includes("Account_Profile_W") ? <li>
                                        <Link to="/user-account-management" onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu11' className="p-0" aria-expanded="false">Account Management</Link>
                                    </li> : <></>
                                }
                                {
                                    this.state.permissions?.includes("userMaintenanceR_W") ?
                                        <li>
                                            <Link to="/usermaintenance" onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu12' className="p-0" aria-expanded="false">Users List</Link>
                                        </li> : <></>
                                }
                            </ul>
                        </li>
                        <li>
                            <a href="#" onClick={e => e.preventDefault()} className={subMenu7 ? 'active' : 'unactive'} aria-expanded="false">
                                <span onClick={this.showSubMenu} className='flexJustify' id='menu7' ><span>Reporting Management</span> <i className="fa fa-chevron-right" aria-hidden="true"></i></span>
                            </a>
                            <ul id="sidebar_menu" className={subMenu7 ? 'sub-menu open' : 'sub-menu'}>
                                <li>
                                    <Link to="/reports" onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu13' className="p-0" aria-expanded="false">Reports</Link>
                                </li>
                                <li>
                                    <Link to="/logs" onClick={()=>this.clickSubMenu(NOBMID)} id='subMenu14' className="p-0" aria-expanded="false">Logs</Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <a href="#" aria-expanded="false" onClick={this.showSubMenu} id="menu9">
                                <span  className="flexJustify"> <span>Dealer Portal</span></span>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
}